/* global console, ApplePaySession */
/* eslint-disable no-nested-ternary, no-loop-func, camelcase, vars-on-top, no-invalid-this, indent, multiline-ternary */
(function (root) {
	'use strict';

	var applePaySession,
		applePayChildUrl,
		v5HooksAdded,
		v4HooksAdded,
		isBrandMode,
		isHeadless = false,
		brandModeNavEnabled = false,
		accessoDataLayer = {},
		DEVICE_DESKTOP = 'desktop',
		DEVICE_TABLET = 'tablet',
		DEVICE_TV = 'tv',
		DEVICE_MOBILE = 'mobile',
		accesso = {},
		options = {
			autoscroll: true,
			emailText: 'Email Address',
			l: undefined,
			phoneText: 'Phone Numer',
			promoDefault: '',
			promoSubmit: 'Use Code',
			promoText: 'Promo Code',
			quantity: 0,
			receiptSubmit: 'Find Receipt',
			script_name: 'accesso.js',
			templates: {
				brandmode:
					'<div class="accesso-frame">' +
					'<iframe title="Accesso E-commerce Store Overlay" id="accesso-brand-mode-iframe" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen allow="payment; geolocation; microphone; camera" frameBorder="0" onload="this.contentWindow.focus()" width="100%" height="100%" src="{{url}}/{{path}}?{{qs}}&brandmode=true">' +
					'</iframe>',
				overlay:
					'<div class="accesso-backdrop"></div>' +
					'<div id="overlayCloseContainer">' +
					'<div class="accesso-close" role="button" aria-label="Close Store" tabindex="0"></div>' +
					'<div class="accesso-frame">' +
					'<iframe title="Accesso E-commerce Store Overlay" id="override" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen allow="payment; geolocation; microphone; camera" frameBorder="0" onload="this.contentWindow.focus()" width="100%" height="100%" src="{{url}}/{{path}}?{{qs}}">' +
					'</iframe>' +
					'</div>' +
					'</div>',
				promo:
					'<form class="promoform">' +
					'<input class="promotxt" type="text" placeholder="{{promoText}}" value="{{promoDefault}}">' +
					'<input class="promobtn" type="submit" value="{{promoSubmit}}">' +
					'</form>',
				quickcart:
					'<h3>Shopping Cart</h3>' +
					'<div>' +
					'<strong>Items:</strong>' +
					'<span class="accesso quantity" data-accesso-quantity>0</span>' +
					'<span class="divider">&nbsp;</span>' +
					'<strong>Total:</strong> $' +
					'<span class="accesso total" data-accesso-total>0.00</span>' +
					'</div>' +
					'<div>' +
					'<a data-accesso-launch="cartView" href="#checkout">Checkout</a>' +
					'<span class="divider">|</span>' +
					'<a data-accesso-launch="reset" href="#clearcart">Clear Cart</a>' +
					'</div>',
				receiptform:
					'<form class="receiptform">' +
					'<div>' +
					'<label>{{emailText}}</label>' +
					'<input name="cc" placeholder="{{emailText}}" type="text" class="receiptemailtxt" value="">' +
					'</div>' +
					'<div>' +
					'<label>{{phoneText}}</label>' +
					'<input placeholder="{{phoneText}}" name="ph" class="receiptpntxt" type="text" value="">' +
					'</div>' +
					'<div>' +
					'<label>&nbsp;</label>' +
					'<input class="receiptbtn" type="submit" value="{{receiptSubmit}}">' +
					'</div>' +
					'</form>',
				shim: '<iframe style="visibility: hidden" src="{{url}}/embed/shim.html?_v=5.20"></iframe>'
			},
			total: '0.00',
			url: ''
			// shim.html has a version number on it for cache busting. Update that number each time a new shim.html is released.
		};

	isBrandMode = isBrandModeEcommerce(options.script_name);
	isHeadless = isHeadlessStore(options.script_name);
	if (isBrandMode) {
		brandModeNavEnabled = getShowNavbarInBrandMode(options.script_name)
	}

	/**
	 * Returns the matches function.
	 * @public
	 * @returns {String|undefined} the name of the function
	 */
	function getMatchesFn() {
		var a = document.documentElement,
			selectors = ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector', 'matchesSelector'],
			i = 0;

		for (; i < selectors.length; i++) {
			if (a[selectors[i]]) {
				return selectors[i];
			}
		}
	}

	/**
	 * Warns on all arguments
	 * @private
	 * @returns {undefined}
	 */
	function warn() {
		/* eslint-disable no-console */
		if (console && console.warn) {
			console.warn.apply(console.warn, arguments);
		}
		/* eslint-disable no-console */
	}

	/**
	 * Returns the type of device
	 * @param {String} ua The user agent
	 * @returns {String} The type of device
	 */
	function userAgentMatch(ua) {
		return ua.match(/GoogleTV|SmartTV|Internet.TV|NetCast|NETTV|AppleTV|boxee|Kylo|Roku|DLNADOC|CE-HTML/i) ? DEVICE_TV
			: ua.match(/Xbox|PLAYSTATION.3|Wii/i) ? DEVICE_TV
				: ua.match(/iPad/i) || ua.match(/tablet/i) && !ua.match(/RX-34/i) && !ua.match(/Windows NT/) || ua.match(/FOLIO/i) ? DEVICE_TABLET
					: ua.match('Windows NT') && ua.match('ARM') ? DEVICE_TABLET
						: ua.match(/Linux/i) && ua.match(/Android/i) && !ua.match(/Fennec|mobi|HTC.Magic|HTCX06HT|Nexus.One|SC-02B|fone.945/i) ? DEVICE_TABLET
							: ua.match(/Kindle/i) || ua.match(/Mac.OS/i) && ua.match(/Silk/i) ? DEVICE_TABLET
								: ua.match(/GT-P10|SC-01C|SHW-M180S|SGH-T849|SCH-I800|SHW-M180L|SPH-P100|SGH-I987|zt180|HTC(.Flyer|_Flyer)|Sprint.ATP51|ViewPad7|pandigital(sprnova|nova)|Ideos.S7|Dell.Streak.7|Advent.Vega|A101IT|A70BHT|MID7015|Next2|nook/i) || ua.match(/MB511/i) && ua.match(/RUTEM/i) ? DEVICE_TABLET
									: ua.match(/BOLT|Fennec|Iris|Maemo|Minimo|Mobi|mowser|NetFront|Novarra|Prism|RX-34|Skyfire|Tear|XV6875|XV6975|Google.Wireless.Transcoder/i) ? DEVICE_MOBILE
										: ua.match(/Opera/i) && ua.match(/Windows.NT.5/i) && ua.match(/HTC|Xda|Mini|Vario|SAMSUNG-GT-i8000|SAMSUNG-SGH-i9/i) ? DEVICE_MOBILE
											: ua.match(/Windows.(NT|XP|ME|9)/) && !ua.match(/Phone/i) || ua.match(/Win(9|.9|NT)/i) ? DEVICE_DESKTOP
												: ua.match(/Macintosh|PowerPC/i) && !ua.match(/Silk/i) ? DEVICE_DESKTOP
													: ua.match(/Linux/i) && ua.match(/X11/i) ? DEVICE_DESKTOP
														: ua.match(/Chrome/i) && ua.match(/CrOS/) ? DEVICE_DESKTOP
															: ua.match(/Solaris|SunOS|BSD/i) ? DEVICE_DESKTOP
																: ua.match(/Bot|Crawler|Spider|Yahoo|ia_archiver|Covario-IDS|findlinks|DataparkSearch|larbin|Mediapartners-Google|NG-Search|Snappy|Teoma|Jeeves|TinEye/i) && !ua.match(/Mobile/i) ? DEVICE_DESKTOP
																	: DEVICE_MOBILE;
	}

	/**
	 * Find out if this is a landing page
	 * @returns {Boolean} true/false
	 */
	function isLandingPage() {
		return window.location.pathname.indexOf('store.php') > -1 || window.location.pathname.indexOf('aaa.jsp') > -1;
	}

	/**
	 * Find out if this is a AAA landing page
	 * @returns {Boolean} true/false
	 */
	function isAAAPage() {
		return window.location.pathname.indexOf('aaa.jsp') > -1;
	}

	/**
	 * Find out if we should launch a new window
	 * @param {String} deviceType The device type
	 * @returns {Boolean} true/false
	 */
	function launchNewWindow(deviceType) {
		return options && !isBrandMode && (options.new_window === "all" || options.new_window === deviceType || (options.new_window === "true" && deviceType === "mobile"));
	}

	/**
	 * Populate data layer with information from special attribute data
	 * This will allow for instances where specific information needs to be able to be passed
	 * to the accessoDataLayer prior to the store launching using the URLHook method
	 * Clients can add an attribute before the accesso.js code loads if desired and accesso.js will then be able to access it
	 * during the launch process.
	 * @returns {undefined}
	 */
	function populateDataLayerFromDataLayerAttribute() {
		var dataLayerTags = document.querySelectorAll('[data-datalayer]'),
			i = 0,
			attributeData;

		if (dataLayerTags.length > 0) {
			//loop through the attribute data to append information to the data layer
			for (; i < dataLayerTags.length; i++) {
				attributeData = dataLayerTags[i].getAttribute('data-datalayer').split(',');
				attributeData.forEach(function (item) {
					var keyValues = item.split('=');

					if (keyValues.length === 2) {
						//add the params to the data layer - NOTE there is no need to encode these URI components because
						//when these officially become evaluated, the encoding action happens at that time so we are good here
						accesso.updateAccessoDataLayer(keyValues[0], keyValues[1]);
					}
				});
			}
		}
	}

	/**
	 * URL - Utility class for parsing raw URLs
	 * @param {String} uri The uri
	 * @returns {self} Our instance
	 */
	function URL(uri) {
		this.uri = uri;

		/**
		 * Inspects a URL and breaks it into an array of key value pairs.
		 * @return {Array<Object>} the parsed url. The format is {k: key, v: val}
		 */
		this.parts = function () {
			var qs = this.queryString(),
				pairs;
			if (qs === null) {
				return [];
			}

			// ignore fragments
			pairs = qs.split("#");

			// parse through the string.
			pairs = pairs[0].split("&");


			return pairs.map(function (x) {
				var parts = x.split("=");

				switch (parts.length) {
					case 1:
						return {
							k: parts[0],
							v: ''
						};
					case 2:
						return {
							k: parts[0],
							v: parts[1]
						};
					case 3:
						return {
							k: parts[0],
							v: parts.splice(1, 2).join('=')
						};
					default:
						throw new Error("Invalid URL Query String: too many ='s for parameter in query string");
				}
			});
		};

		/**
		 * Gets the query string part of a url
		 * @return {string} - the query string part of a url
		 */
		this.queryString = function () {
			var idx = this.uri.indexOf('?');

			if (idx == -1 || idx + 2 >= this.uri.length) {
				return null;
			}

			return this.uri.substring(idx + 1);
		};
	}

	/**
	 * URLHook - Base class for doing URL hooks. The base class can determine if a given url matches a hook and if there is a match, it can produce a string usable for redirects using basic templating syntax.
	 * @param {string} matchParam - A regular expression indicating when a parameter should match. This parameter can optionally be an object of the following format: {conditions: [Array], predicate: URLHook.AND|URLHook.OR}.
	 * @param {string} template A string template indicating the replacements that should be performed. Eg: "{0}{1}". The index parameters come from the capture groups of the regular expression. For example, 0 is capture group 1.
	 * @returns {self} Our instance
	 */
	function URLHook(matchParam, template) {
		if (matchParam instanceof RegExp) {
			this.matchParam = {
				conditions: [matchParam],
				predicate: [URLHook.AND]
			};
		} else {
			this.matchParam = matchParam;
		}

		this.template = template;

		/**
		 * Determines is a given url matches this URLHook.
		 * @param {URL} url - The url to check
		 * @return {boolean} - true if ite matches, otherwise false.
		 */
		this.matches = function (url) {
			return this.getMatch(url) !== null;
		};

		/**
		 * Gets the first (and only) match from a url. If more than one pair exists it throws an exception.
		 * @param{URL} url - The url to extract it from.
		 * @return{object} - The matched key value pair. Format is {k:, v:}.
		 */
		this.getMatch = function (url) {
			var parts = url.parts(),
				mp = this.matchParam,
				matchArr = [],
				i = 0,
				matches,
				c;


			// for each condition, determine if there is a match
			for (; i < mp.conditions.length; i++) {
				c = mp.conditions[i];
				matches = parts.filter(function (x) {
					return c.exec(x.k + "=" + x.v) !== null;
				});

				if (matches.length > 1) {
					throw new Error("More than one match candidate found for the given url. Please refine your regular expressions to match only one candidate.");
				}

				if (matches.length === 1) {
					matchArr.push(matches[0]);
				}
			}

			// determine if we need an and or or
			if (mp.predicate == URLHook.AND && matchArr.length === mp.conditions.length) {
				return matchArr;
			} else if (mp.predicate == URLHook.OR && matchArr.length >= 1) {
				return matchArr;
			} else {
				return null;
			}
		};

		/**
		 * Takes a matching URL and does the template replacement needed to redirect the browser.
		 * @param{URL} uri - The url to parse.
		 * @return{string} - The transformed URL.
		 */
		this.executeWith = function (uri) {
			var splits = this.getReplacements(uri),
				buff = this.template,
				i = 0;

			for (; i < splits.length; i++) {
				buff = buff.replace(new RegExp("\\{" + i + "\\}", "g"), splits[i]);
			}

			return buff;
		};

		/**
		 * Applies the transformations to the matching url and returns them as an array of match groups.
		 * @param{uri} uri - The uri to match.
		 * @return {Array} the matched groups
		 */
		this.getReplacements = function (uri) {
			var partAr = [],
				bits = this.getMatch(uri),
				i = 0,
				bit,
				mp,
				parts;

			for (; i < bits.length; i++) {
				bit = bits[i];
				mp = this.matchParam.conditions[i];

				// extract the captured parts
				parts = mp.exec(bit.k + "=" + bit.v);

				// get the higher order parts
				partAr.push(parts.splice(1));
			}

			// concat the array
			return partAr.reduce(function (acc, x) {
				return acc.concat(x);
			});
		};
	}

	/**
	 * Possible predicates
	 */
	URLHook.AND = 1;
	URLHook.OR = 2;

	URLCallbackHook.prototype = new URLHook();

	/**
	 * Our hook specifically for accesso-pay related stuff.
	 * @returns {URLHook} Our url hook
	 */
	function PayURLHook() {
		URLHook.apply(this, arguments);
	}
	PayURLHook.prototype.constructor = URLHook;

	/**
	 * URLCallbackHook - Subclass of URLHook. It differs from URLHook in that it is able to provide a callback that takes in the matched parameters.
	 * @param {*} matchParam The match param
	 * @param {Function} callback The callback
	 * @returns {self} Our instance
	 */
	function URLCallbackHook(matchParam, callback) {
		URLHook.call(this, matchParam, "");
		this.callback = callback;
		this.executeWith = function (uri) {
			return callback(uri, this.getReplacements(uri));
		};
	}

	var qs = {
		/**
		 * Parse a GET QueryString from a URL into an object of keys and values
		 * @param {String} str The string to test
		 * @return {Object} Object of parsed queryString
		 */
		parse: function (str) {
			var object = {};
			str.replace(new RegExp("([^?=&]+)(=([^&]*))?", "g"), function ($0, $1, $2, $3) {
				object[$1] = $3;
			});
			return object;
		},
		/**
		 * Render an object of keys/values into a GET QueryString
		 * @param {Object} obj The object to stringify
		 * @return {String} rendered QueryString
		 */
		stringify: function (obj) {
			var array = [];
			for (var p in obj) {
				if (obj.hasOwnProperty(p)) {
					array.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
				}
			}
			return array.join("&");
		}
	};

	var overlay = {
		close: function () {
			// close the iframe
			document.body.removeChild(document.querySelector('#accesso'));
		},
		/**
		 * Open store overlay at a given path with any supplied GET arguments
		 * @param {Event} event The event object
		 * @param {Object} path The path
		 * @param {Object} args The arguments
		 * @param {String} keyword The keyword
		 * @return {Object} obj2 with the contents of obj21 merged in
		 */
		open: function (event, path, args, keyword) {
			var brandmodeIframe = document.getElementById('accesso-brand-mode-iframe');

			// This will prevent the native scrolling behaviour when a hook lives on an anchor tag with href="#"
			if (!isBrandMode && event !== null) {
				event.preventDefault();
			}

			// do not launch store if the overlay exists
			if (document.getElementById('accesso') !== null && !isBrandMode) {
				return;
			}

			if (isBrandMode && brandModeNavEnabled) {
				args = merge(args, { brandmodenav: 'true' });
			}

			if (options.url) {
				if (options.m) {
					args = merge(args, { _m: options._m });
				}
				if (options.e) {
					args = merge(args, { _e: options.e });
				}
				if (options.l) {
					args = merge(args, { l: options.l });
				}
				if (options.d) {
					args = merge(args, { _d: options._d });
				}
				if (options.a) {
					args = merge(args, { _a: options._a });
				}
				if (isHeadless) {
					args = merge(args, { headless: 'true' });
				}
				if (options.search !== undefined && options.search.length > 1) {
					var query = qs.parse(options.search);
					[
						'acsop_aid', 'acsop_er', 'acsop_erd', 'acsop_pid',
						'acsop_prid', 'acsop_pt', 'acsop_bsc', 'acsop_pi', 'acsop_co', 'acsop_reco', 'acsop_st',
						'acsop_t', 'cart_id', 'cart_key', ,'s_id', 'session_id'
					].forEach(function (key) {
						var data = {};
						if (query[key]) {
							data[key] = query[key];
							args = merge(args, data);
						}
					});

					if (query.view) {
						path = query.view
					}
					if (query.view == "cartRecovery" && query.email) {
						path += ("/" + query.email)
					}

					// Merge in the other parameters from the url
					try {
						args = merge(args, query);
					} catch (e) {
						// ignore
					}
				}

				// If no path is provided, attempt to get it from the hooks
				if (!path) {
					path = getUrlFromHooks();
				}

				try {
					if (args === undefined) {
						args = {};
					}

					// Look for special window objects
					if (window.sfCustomerID !== undefined) {
						args = merge(args, { sfCustomerID: window.sfCustomerID });
					}

					if (window.sfPark !== undefined) {
						args = merge(args, { sfPark: window.sfPark });
					}

					// merge the data layer into the arguments
					args = merge(args, accessoDataLayer);
				} catch (e) {
					// ignore
				}

				var ua = window.navigator ? window.navigator.userAgent : window.request ? window.request.headers['user-agent'] : 'No User Agent';
				var formFactor = userAgentMatch(ua);
				var launchMobileSite = !isBrandMode && (formFactor === DEVICE_TABLET || formFactor === DEVICE_MOBILE);
				var launchUrl = options.url + '/' + path + '?' + qs.stringify(args);

				/** Landing page functionality **/
				if (!isBrandMode && typeof window.accessoClient !== 'undefined' &&
					typeof window.accessoClient.landingPageURL !== 'undefined') {
					if (launchMobileSite && !isAAAPage()) {
						if (launchNewWindow(DEVICE_MOBILE)) {
							window.open(launchUrl, '_blank');
						} else {
							window.location.assign(launchUrl);
						}
					} else {
						var landingPageURL = window.accessoClient.landingPageURL;
						if (landingPageURL.indexOf('?') > -1) {
							var parameters = landingPageURL.split('?');

							if (parameters[1] === '') {
								landingPageURL += 'keyword=' + keyword;
							} else {
								landingPageURL += '&keyword=' + keyword;
							}
						} else {
							landingPageURL += '?keyword=' + keyword;
						}
						document.location = landingPageURL;
					}

					return;
				}

				/*
				 *	The conditional logic is written a bit backwards, but it matches the implementation.
				 *	By default, we scroll. It is only if autoscroll is 'false' that we do not.
				 */
				var autoscroll = (options.autoscroll !== 'false');

				if (autoscroll && !isBrandMode) {
					if (window.top.innerHeight <= 630) {
						// We cutoff the X on smaller screen sizes to maximize real estate above the fold
						window.scrollTo(0, 148);
					} else if (window.top.innerHeight <= 768) {
						// Absolute positioned iFrame scenario
						window.scrollTo(0, 108);
					} else {
						// Static positioned iFrame scenario
						window.scrollTo(0, 0);
					}
				} else if (!isBrandMode) {
					/*
					 *	There is a specific scenario we need to handle here.
					 *	When the screen is within certain dimensions - less than 1024 width OR less than 840 height,
					 *	the iframe is positioned absolutely instead of fixed - causing it to display at the very top of the document.
					 *	When autoscroll is on, this is okay because we also scroll to the top and the iframe remains in view.
					 *	When autoscroll is off, this causes the iframe to move to the top of the document while the viewport remains in place,
					 *	causing the iframe to be out of view.
					 *
					 *	In order to adjust for this, the most simple and least risky approach is to identify this scenario
					 *	and offset the positioning from the top.
					 */

					// Accounts for offset on tinier screens, scrolls down 45 pixels and then negatively positions the
					// iframe to cutoff the "X" icon if the user hasn't scrolled down on the page or the store auto launches.
					if (window.scrollY <= 45) {
						window.scrollTo(0, 45);
					}

					style('@media(max-width:1024px), (max-height:840px) {#overlayCloseContainer', ["top:" + (window.pageYOffset - (window.top.innerHeight <= 630 ? 70 : 100)) + "px;"]);
				}

				if (launchMobileSite && !isAAAPage()) {
					if (launchNewWindow(DEVICE_MOBILE)) {
						window.open(gaLinker(launchUrl), '_blank');
					} else {
						window.location.assign(gaLinker(launchUrl));
					}
				} else if (!isLandingPage()) {
					//check the launch new window status for desktop
					if (launchNewWindow(DEVICE_DESKTOP)) {
						window.open(launchUrl, '_blank');
						return;
					}

					var container = document.createElement('div'),
						templateParams = {
							path: path,
							qs: qs.stringify(args)
						},
						iframeContainer;

					container.setAttribute('id', isBrandMode ? 'accesso-brand-mode-container' : 'accesso');
					container.innerHTML = render(isBrandMode ? options.templates.brandmode : options.templates.overlay, templateParams);

					iframeContainer = container.getElementsByTagName("iframe")[0];
					iframeContainer.src = sourceParamAdjuster(container);

					if (!isBrandMode || !brandmodeIframe) {
						// Unregister the initial listeners
						window.removeEventListener("message", initialListeners, false);

						window.addEventListener("message", function processWillCloseMessage(message) {
							var msg,
								scriptToAppend,
								postBackMsg,
								queryParamParts,
								trimmedQueryParams = [];
							try {
								msg = JSON.parse(message.data);
								if (!msg || typeof msg.messageType !== 'string' || msg.messageType.indexOf('accesso') === -1) {
									return;
								}

								switch (msg.messageType) {
									case 'accesso:purchaseSuccess':
										fireAccessoEvent('purchase', { cToken: msg.cToken, oToken: msg.oToken });
										break;
									case 'accesso:willClose':
										window.removeEventListener("message", processWillCloseMessage, false);
										overlay.close();
										break;
									case 'accesso:getOriginOfParent':
										postBackMsg = JSON.stringify({
											messageType: 'accesso:parentOriginInfo',
											parentOriginUrl: window.location.protocol + '//' + window.location.host
										});
										iframeContainer.contentWindow.postMessage(postBackMsg, '*');
										break;
									case 'accesso:checkForApplePay':
										postBackMsg = JSON.stringify({
											isAvailable: typeof window.ApplePaySession === 'function' && ApplePaySession.supportsVersion(msg.version) && ApplePaySession.canMakePayments(),
											messageType: 'accesso:applePayAvailability'
										});
										iframeContainer.contentWindow.postMessage(postBackMsg, applePayChildUrl);
										break;
									case 'accesso:applePayStartSession':
										startApplePaySession(iframeContainer, msg, message);
										break;
									case 'accesso:applePayCancel':
										if (applePaySession) {
											applePaySession.oncancel();
										}
										applePaySession = null;
										break;
									case 'accesso:applePayValidateResponse':
										if (applePaySession) {
											applePaySession.completeMerchantValidation(msg.data);
										}
										break;
									case 'accesso:applePayPaymentResponse':
										if (applePaySession) {
											applePaySession.completePayment({ status: msg.status });
										}
										break;
									case 'accesso:applePayShippingMethodUpdated':
										if (applePaySession) {
											applePaySession.completeShippingMethodSelection(msg.data);
										}
										break;
									case 'accesso:applePayShippingContactUpdated':
										if (applePaySession) {
											applePaySession.completeShippingContactSelection(msg.data);
										}
										break;
									case 'accesso:getCurrentUrl':
										postBackMsg = JSON.stringify({
											messageType: 'accesso:setCurrentUrl',
											url: root.location.href
										});
										iframeContainer.contentWindow.postMessage(postBackMsg, '*');
										break;
									case 'accesso:accessoPayRedirect':
										if (msg.method === 'POST') {
											var redirectFormId = 'acsp_rdfrm_main';
											var redirectForm = document.getElementById(redirectFormId);
											var form,
												formField,
												key;
											if (!redirectForm) {
												form = document.createElement('form');
												document.body.appendChild(form);
											}
											form.id = redirectFormId;
											form.name = redirectFormId;
											form.action = msg.url ? msg.url.replace(/(<([^>]+)>)|^javascript/ig, '') : '';
											form.enctype = 'application/x-www-form-urlencoded';
											form.innerHTML = '';
											form.style.display = 'none';
											form.method = msg.method;
											for (key in msg.redirectParams) {
												formField = document.createElement('input');
												formField.type = 'hidden';
												formField.name = key;
												formField.value = msg.redirectParams[key];

												form.appendChild(formField);
											}
											form.submit();
										} else {
											root.location.href = msg.url ? msg.url.replace(/(<([^>]+)>)|^javascript/ig, '') : '';
											return;
										}
										break;
									case 'accesso:accessoPayClearQueryParams':
										if (Array.isArray(msg.queryParams)) {
											try {
												queryParamParts = new URL(root.location.href).parts();
											} catch (err) {
												queryParamParts = [];
											}
											queryParamParts.forEach(function trimQueryParams(qp) {
												if (msg.queryParams.indexOf(qp.k) === -1) {
													trimmedQueryParams.push(qp.k + '=' + qp.v);
												}
											});
											root.history.replaceState({}, document.title, root.location.pathname + (trimmedQueryParams.length ? '?' + trimmedQueryParams.join('&') : ''));
										}
										break;
									case 'accesso:updateBrandModeHeight':
										var brandIframe = document.getElementById('accesso-brand-mode-iframe');
										if (brandIframe) {
											brandIframe.style.height = Math.max(400, msg.height + 16) + 'px';
										}
										break;
									case 'accesso:cartUpdate':
										if (msg.data.qty >= 0 && msg.data.total >= 0) {
											updateCarts('v5', {
												qty: msg.data.qty || 0,
												total: msg.data.total || 0
											});
										}
										break;
									case 'accesso:didRoute':
										if (isBrandMode) {
											window.scrollTo({
												behavior: 'smooth',
												top: 0
											})
										}
										break;
									default:
								}
							} catch (e) {
								// ignore
							}
						});
					}

					if (isBrandMode) {
						if (!brandmodeIframe) {
							document.getElementById(getAccessoScriptElement(options.script_name).dataset.accessoBrandMode).appendChild(container);
						} else {
							brandmodeIframe.contentWindow.postMessage(JSON.stringify({
								messageType: 'accesso:brandModeRouting',
								routePath: path
							}), '*');
						}
					} else {
						document.body.appendChild(container);

						// tell the store we'd like to close
						document.querySelector('#accesso .accesso-close').onclick = function () {
							iframeContainer.contentWindow.postMessage("accesso:requestsClose", "*");
						};

						document.querySelector('#accesso .accesso-close').onkeydown = function (e) {
							// tell the store we'd like to close
							if (e.keyCode === 13 || e.code === 'Enter') {
								iframeContainer.contentWindow.postMessage("accesso:requestsClose", "*");
							}
						};
					}
				}
			}
		},

		setupStyles: function () {
			style('#accesso', [
				"position:fixed;",
				"top:0;",
				"bottom:0;",
				"width:100%;",
				"height:100%;",
				"justify-content:center;",
				"align-content:center;",
				"z-index:2147483647;"
			]);
			style('#overlayCloseContainer', [
				"display:flex;",
				"flex-direction:column;",
				"justify-content:center;",
				"max-width:1280px;",
				"width:100%;",
				"height:100%;",
				"margin:0 auto;",
				"padding:20px 40px 40px 40px;",
				"position:absolute;",
				"top:0;",
				"bottom:0;",
				"right:0;",
				"left:0;"
			]);
			style('#accesso .accesso-frame', [
				"width:100%;",
				"height:100%;",
				"max-height:960px;",
				"overflow:hidden;"
			]);
			style('#accesso .accesso-frame iframe', [
				"width:100%;",
				"height:100%;",
				"max-height:960px;",
				"margin:0 auto;",
				"border:none !important;",
				"border-radius:16px !important;",
				"background-color:white !important;"
			]);

			var base64 = "iVBORw0KGgoAAAANSUhEUgAAABwAAAAeCAYAAAA/xX6fAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEcElEQVRIia2Wz28TRxTHv2921o6DY0IihxrHpD8gQo3EoaiqiFrIibqqeughvdH+A6hqkx6rdqVKFQfwDYmCAof0UlU90B8qEhdfGksVgkOhHHAQqI5c7JA4thXvr5nXQ+xgkt2QlHwvuzvvvf3Mmx9vhrBz0dqDO58c6hkevLUsyxKW9Ytx6NCQsO24UOoBASkYxhMuleoKSGpgQgOWfiEgM9PY2EdmpXJn0DTrZ4hwAsAIwJm2fYWI/tJa/EoU+7lcThSBD9RW4DAgAZPiwIGbhwDnMoB3ntfztr4n6vlmYeHYPPCjCnIwguMmjXR67jNmb5aIRrcJA4CjzOp0PP6P22w2/sTTiV6X2NhgWZZIpeYsZj5HRHt3AAMAEHG/EHwulUp/zbx5BDc2UDqd/pyZz+8UFCAmoi8WFhZyocBMJvOaUuomgP5dAIKZakp5xyqVyoNOW/eQktb6q92CAWvDK6W8gq7EOkDKZDKvMvPHQYGJRALZbDb0x9lsFolEIsx8IpVKDT4DtCyLlMKHYRHj4+OYmZnB1NTUJtvU1BRmZmZw/PjxsHACxKeAJYD2tsjnYfT1LX4LYCQoolgsgogwPT0NACgUCuuw6elp5HI5zM7OhgEBgJrNxizwUEsANDzsmErxUaLwwnP+/NrC7UA777lcbt0WSiOMAJAAfAkA9p6kjDQePXfPbYRuB9bWweTrSVn9m0kAFqlKdFMBCBMzB74/T9XysgEQJGBB970v4FMJ4OGtgrrnDHg20607iXo/x6kGCAkAJExmpgWicGA3rHsYtwcV92qoAZiEAYB6xSumlIujRPqtIPdsNouzZ89ughUKhfXVe/fuXczPz4cAI1ebOvUHWtdcAkDYf6o3qRujpvnoVpB7IpHA+Pg4rl+/Hvi7bDaLubk51Ov1IDN73ksnq9XBW8CN1fY+eC86MKCGotF7PxGpN0O6+b+kIa+t2GNn0gOoFou/O+3V2VKeF2kpNfQlMxq7BWNGXftDFwwXTrHYUsB6Lc2r3l6n5TgH7jPFvts1IO255ODgfDMWbwF5BXSd+EeO7MPiYpxcHrgfMepRIv+NF4Jx7LLrHL7oS3/ZrdxfBarPAsvlMg8NDTL7JFwk7whq2gRvjAjRnYHQBHovNOwjF6UQtZXq8gpw20P7utFdYbhUetcRQq8IUzxx7dErCiOfMEd+Q8DdJBAGcctVw6dte/RqhHRNSqfGXLABrN/igqq12L//VKyheK/Qsl+Q3GMa1Yw0qicFvLcB/zAR+tayEWUA/zL13Hb9gz8o3bOkNK9q4ddWDazg8Y1WNywMCGDSwDAi8VozbhiiTwn0GhpRNrQpweQBMGWUfN/VIENHiJVScLS2V7VW9cYgmniYdzfCtgB2bMdkMtkXte14j2m6Md+gqKlN6YApYkryPKWFoTxDRly37trNploFXnaBSz5CpmE7V32amJgwSqWYUSy2zH37pLHcNgyQyUtLvgI8NTEBP5/P66Csdgrc4M+dMMLTLLZ9Tv0HO+L4EuCc5z0AAAAASUVORK5CYII=";

			style('#accesso .accesso-close', [
				"width:32px;",
				"min-height:32px;",
				"height:32px;",
				"color:#f6f6f6;",
				"float:right;",
				"align-self:flex-end;",
				"cursor:pointer;",
				"background:transparent url(data:image/jpeg;charset=utf-8;base64," + base64 + ") no-repeat center center;"
			]);

			var rgba = "rgba(0,0,0,0.6)";
			var rgbaL = "(left," + rgba + "0%," + rgba + "100%);";
			style('.accesso-backdrop', [
				"position:fixed;",
				"top:0px;",
				"left:0px;",
				"right:0px;",
				"bottom:0px;",
				"border:none;",
				"margin:0;",
				"padding:0;",
				"background: linear-gradient" + rgbaL + ";",
				"background: -moz-linear-gradient" + rgbaL + ";",
				"background: -o-linear-gradient" + rgbaL + ";",
				"background: -ms-linear-gradient" + rgbaL + ";",
				"background: -webkit-linear-gradient" + rgbaL + ";"
			]);
		}
	};

	/**
	 * Method used to append the frame source with the needed Google _utm variables for tracking. Used only in overlay.open()
	 * @param {HTMLElement} source The DOM object that contains the accesso iframe.
	 * @returns {undefined}
	 */
	/* eslint-disable */
	function gaLinker(source) {
		if (typeof window.ga !== 'undefined') {
			var clientId;

			try {
				clientId = ga.getAll()[0].get('clientId');
				if (typeof clientId !== 'undefined') {
					if (source.indexOf('?') === -1) {
						source += '?';
					} else {
						source += '&';
					}
					source += '_cid=' + clientId;
				}
			} catch (e) {
				warn('client id for analytics could not be calculated');
			}

			ga(function (tracker) {
				linkerParam = tracker.get('linkerParam');
			});
			ga(function (tracker) {
				var linker = new window.gaplugins.Linker(tracker);
				source = linker.decorate(source);
			});

			return source;
		} else if (window._gat !== undefined) {
			var clientPageTacker = _gat._getTrackerByName();

			return clientPageTacker._getLinkerUrl(source);
		}
		return source;
	}
	/* eslint-disable */

	/**
	 * Method used to find the source of the accesso iFrame and merge any GET parameters from the client with the GET params from Google
	 * @param {HTMLElement} template The template containing the iframe
	 * @return {String} The new frame source with merged GET params.
	 */
	function sourceParamAdjuster(template) {
		// if this fails we DO NOT mess with the src attribute of the iframe.
		try {
			var unlinkedFrame = template.getElementsByTagName("iframe")[0];
			var unlinkedSrc = unlinkedFrame.src.split("?")[0];
			var unlinkedQs = qs.parse(unlinkedFrame.src.split("?")[1]);

			var linkedSrc = gaLinker(unlinkedSrc);
			var linkedQsString = linkedSrc.split("?")[1];
			// if there is no query string, set it to an empty string so we don't fail when trying to parse it
			if (linkedQsString === undefined) {
			    linkedQsString = '';
			}
			var linkedQs = qs.parse(linkedQsString);

			var finalQs = merge(unlinkedQs, linkedQs);
			var finalSrc = unlinkedSrc + "?" + qs.stringify(finalQs);
			return finalSrc;
		} catch (e) {
			return template.getElementsByTagName("iframe")[0].src;
		}
	}

	/**
	 * Merge object 1 into object 2 via a shallow merge
	 * @param {Object} obj1 The first object to merge into
	 * @param {Object} obj2 The object to merge into obj1
	 * @return {Object} obj2 with the contents of obj1 merged in
	 */
	function merge(obj1, obj2) {
		for (var attrname in obj1) {
			obj2[attrname] = obj1[attrname];
		}
		return obj2;
	}

	/**
	 * Fill template with context from an object
	 *
	 * Templates can contain {{key}} which will be replaced via value from a
	 * matching key in the context object
	 * @param {String} template The template to render
	 * @param {Object} context The context data
	 * @return {String} Rendered template
	 */
	function render(template, context) {
		options = options ? options : {};
		var ctx = merge(context, options);
		return template.replace(
			/\{\{(?:#(.+?)#)?\s*(.+?)\s*\}\}/g,
			function (m, cond, id) {
				var rv = ctx[id];
				if (rv === false) {
					return '';
				} else {
					return rv ? (cond || '') + rv : cond ? m : '';
				}
			}
		);
	}

	/**
	 * Get full src of provided script name from DOM
	 * @param {String} script_name The script name
	 * @return {String} contents of src from a <script> matching script_name
	 */
	function scriptSrc(script_name) {
		return getAccessoScriptElement(script_name).src;
	}

	/**
	 * Find out if the client wants to redirect to their own order view instead of our own. This setting makes it
	 * so we pause before routing to the order. If they do this, they must use
	 *
	 * accesso.on('purchase', () => ... )
	 *
	 * To handle the order themselves.
	 *
	 * @param {String} scriptName The script name to find
	 * @returns {Boolean} true/false
	 */
	function isHeadlessStore(scriptName) {
		var el = getAccessoScriptElement(scriptName);
		var headless = el.dataset.headless;
		return typeof headless === 'string' && headless === 'true';
	}

	/**
	 * Find out if the page is running a headless ecommerce
	 * @param {String} scriptName The script name to find
	 * @returns {Boolean} true/false
	 */
	function isBrandModeEcommerce(scriptName) {
		var el = getAccessoScriptElement(scriptName);
		var brandModeId = el.dataset.accessoBrandMode;
		return typeof brandModeId === 'string' && brandModeId.length > 0;
	}

	function getShowNavbarInBrandMode(scriptName) {
		var el = getAccessoScriptElement(scriptName);
		var enableBrandModeNav = el.dataset.enableBrandModeNav;
		return typeof enableBrandModeNav === 'string' && enableBrandModeNav === 'true';
	}

	/**
	 * Returns the accesso.js script element
	 * @param {String} script_name The script name
	 * @returns {HTMLElement} the element
	 */
	function getAccessoScriptElement(script_name) {
		var script_elements = document.getElementsByTagName("script");
		for (var a = 0; a < script_elements.length; a++) {
			var source_string = script_elements[a].src || '';
			if (source_string.indexOf(script_name) >= 0) {
				return script_elements[a];
			}
		}
	}

	/**
	 * Parse a URL into its various significant parts
	 *
	 * @param {String} url
	 * @return {Object} object of parsed URL parts.
	 */
	function parseURL(url) {
		// if coming in we have a hard coded port, preserve the port
		var preservePort = (url.indexOf(':', 6) !== -1);

		var urlInfo = {};
		var parser = document.createElement('a');
		parser.href = url;

		urlInfo.path = parser.pathname.split('/');
		if (!urlInfo.path[0]) {
			urlInfo.path.splice(0, 1);
		}

		if (preservePort == false && parser.host.indexOf(':') > 0) {
			urlInfo.host = parser.host.split(':')[0];
		} else {
			urlInfo.host = parser.host;
		}

		urlInfo.protocol = parser.protocol;

		urlInfo.href = url;

		return urlInfo;
	}

	/**
	 * Run supplied callback when DOM is loaded
	 *
	 * @param {Function} callback
	 * @return null
	 */
	function onDomReady(callback) {
		if (['complete', 'interactive'].indexOf(document.readyState) > -1) {
			return callback.apply();
		}

		document.onreadystatechange = function () {
			if (document.readyState === 'interactive') {
				callback.apply();
			}
		};
	}

	/**
	 * Apply an array of CSS stylesheet rules to the DOM for a given selector
	 *
	 * @param {String} selector
	 * @param {Array} rules
	 * @return null
	 */
	function style(selector, rules) {
		var head = document.head || document.getElementsByTagName('head')[0],
			style = document.createElement('style');

		style.type = 'text/css';
		var cssText = selector + "{";
		rules.forEach(function (rule) {
			cssText += rule;
		});
		cssText += "}";

		if (style.styleSheet) {
			style.styleSheet.cssText = cssText;
		} else {
			style.appendChild(document.createTextNode(cssText));
		}

		head.appendChild(style);
	}


	/**
	 * Add click event to provided element to launch store with given reference
	 *
	 * @param {Object} el
	 * @param {Array} reference
	 * @return null
	 */
	function overlayLaunch(event, reference) {
		if (reference !== undefined && typeof (reference) == 'string') {
			reference = reference.replace('accesso=', '');
		}
		var path = getPath(reference);

		overlay.open(event, path, undefined, reference);
	}

	function getPath(reference) {
		var path = '';
		switch (reference) {
			case "all":
				path = 'packageList';
				break;
			case "checkout":
				path = 'cartView';
				break;
			case "cartView":
				path = 'cartView';
				break;
			case "clearcart":
				path = 'reset';
				break;
			case "reset":
				path = 'reset';
				break;
			case "activation":
				path = 'packageList';
				break;
			case "orderLookup":
				path = 'orderLookup';
				break;
			case "knowledgeBaseView":
				path = 'open-knowledge-base';
				break;
			case "promoBox":
				path = '/packageList/promoOpen';
				break;
			case "profile-login/signin":
				path = '/profile-login/signin';
				break;
			default:
				if (reference) {
					path = 'packageList/keyword/' + reference;
				}
				break;
		}
		return path;
	}

	/**
	 * Render a template into a selector with a given context
	 *
	 * @param {Object} selector
	 * @param {String} template
	 * @param {Object} context
	 * @return null
	 */
	function renderTo(selector, template, context) {
		var els = document.querySelectorAll(selector);

		if (els !== undefined) {
			for (var i = els.length - 1; i >= 0; i--) {
				els[i].innerHTML = render(template);
			}
		}
	}

	/**
	 * Attach an event handler to an element by selector
	 *
	 * @param {String} selector
	 * @param {String} type
	 * @param {Object} callback
	 * @return null
	 */
	function addEvent(selector, type, callback) {
		document.addEventListener(type, function (e) {
			/* eslint-disable no-invalid-this */
			var target = e.target,
				matches = getMatchesFn();

			// loop parent nodes from the target to the delegation node
			for (; target && target != this; target = target.parentNode) {
				if (target[matches](selector)) {
					callback.call(target, e);
					break;
				}
			}
			/* eslint-disable no-invalid-this */
		}, false);
	}

	/**
	 * Publically exposed method for event attachment post dom-ready
	 * @param {String} selector standard CSS selector
	 * @returns {undefined}
	 */
	function attachListener(selector) {
		addEvent(selector, 'click', function (event) {
			if (!this.name && this.href && this.href.split('#').length > 1 && this.href.split('#')[1].length > 0) {
				overlayLaunch(event, this.href.split('#')[1]);
			}
		});
	}

	/**
	 * Makes sure a reference isnt null or undefined
	 * @param {*} reference The reference
	 * @returns {Boolean} true/false
	 */
	function validateReference(reference) {
		return reference !== null && typeof reference !== 'undefined';
	}

	/**
	 * Processes special URL hooks by looking for query parameters and transforming the URL into the required format.
	 * @throws {Error} when multiple hooks match url
	 * @returns {undefined}
	 */
	function processURLHooks() {
		var hookUrl = getUrlFromHooks();

		if (hookUrl) {
			if (isLandingPage()) {
				setIFrameSrcForStorePhp(hookUrl);
			} else {
				overlay.open(null, hookUrl);
			}
		} else if (isBrandMode) {
			// If this is brandmode and we didnt find any hooks, just open the iframe
			overlay.open(null);
		} else if (isLandingPage()) {
			// still need to set the iframe source php
			setIFrameSrcForStorePhp('');
		}
	}

	/**
	 * Tries to assemble a url based of a specified list of hooks
	 * @returns {String} The URL
	 */
	function getUrlFromHooks() {
		var hooks = [
			new PayURLHook({
				conditions: [/(acsop_st)=(.*)/, /acsop_(reco|prid)=(.*)/, /(acsop_t)=(.*)/],
				predicate: URLHook.AND
			}, "accesso-pay"),
			new PayURLHook(/module=paypal(Complete|Cancelled)/, "paypal{0}"),
			new URLHook(/(promo_?code|promoCode)=(.*)/, "packageList/promocode/{1}"),
			new URLHook(/(keyword)=(.*)/, "packageList/keyword/{1}"),
			new URLHook(/(package|packageID|package_id)=(.*)/, "packageDetails/{1}"),
			new URLHook(/(promo_?code|promoCode)=(.*)\/(gift_code)=(.*)/, "packageList/promocode/{1}/gift_code/{3}"),
			new URLHook({
				conditions: [/(profile_email)=(.*)/, /(profile_temp_password)=(.*)/],
				predicate: URLHook.AND
			}, "profile-manager/changePasswordFromEmail/{1}/{3}"),
			new URLHook(/(swap_token)=(.*)/, "profile-manager/changePasswordFromEmailToken/{1}"),
			/** profile-manager-new is for the UD only profile-manager which is newly design and has an app config
			 * as of May 2024 with an app config route_to_new_profile_manager
			 * keeping existing profile-manager for easy roll back if needed
			 */
			new URLHook(/(cultural_member_benefits)=(.*)/, "cultural-member-benefits/reset"),
			new URLHook({
				conditions: [/(flow)=(.*)/, /(keywordforfolio)=(.*)/, /(folioid)=(.*)/],
				predicate: URLHook.AND
			}, "{1}/{3}/{5}"),
			new URLHook(/(gift_membership)=(.*)/, "gift-membership"),
			new URLHook(/(open_promo_box)=(.*)/, "/packageList/promoOpen"),
			new URLHook(/(open_knowledge_base)=(.*)/, "/open-knowledge-base"),

			/**
			 * the module URLCallback hook indicates the module and the subsequent state parameters to call
			 * For example, to call the calendar module with a keyword of guestadmissions and a delay of 4 days, use this query string
			 *   v5.html?module=calendar,guestadmissions,4
			 */
			new URLCallbackHook({
				// could break this into /(module)=(.*)/ and /(params)=(.*)/
				conditions: [/(module)=(.*)/],
				// doesn't matter, because there's only one condition.
				predicate: URLHook.AND
			}, function (uri, matches) {
				/**
				 * matches is an array of the parameters passed in, starting with module at position 0.
				 * matches[0] = module
				 * matches[1] = params for module
				 */
				var result = "",
					params = matches[1].split(',');

				params.forEach(function (p) {
					p = decodeURIComponent(p).trim();
					if (p.length > 0) {
						result += '/' + p;
					}
				});
				return result;
			}),

			/**
			 * Callback hook that transforms the options.search value to be normalized. This requires that we must match all conditions
			 * in order to do a rewrite.
			 */
			new URLCallbackHook({
				conditions: [
					/(cart_?id)=(.+)/,
					/(cart_?key)=(.+)/
				],
				predicate: URLHook.AND
			}, function () {
				// uniformly set the cart key and cart id
				options.search = options.search.replace(new RegExp("cartid", "g"), "cart_id");
				options.search = options.search.replace(new RegExp("cartkey", "g"), "cart_key");
				options.search = options.search.replace(new RegExp("sessionid", "g"), "session_id");

				// we return "" here to indicate to navigate to the root of the application
				return "";
			})
		];

		var hook;
		var matchingHooks = hooks.filter(function (x) {
			return x.matches(new URL(document.URL));
		});
		var paymentHook;

		/**
		 * This exists to defeat possible configuration ambiguity (and possibly to help detect bugs)
		 * The only time we don't want to do it is if we find an accesso pay hook because we need to
		 * it will match multiple hooks for needing session_id, cart_id, etc plus the accesso pay stuff.
		 */
		if (matchingHooks.length > 1) {
			paymentHook = matchingHooks.find(function (hook) {
				return hook instanceof PayURLHook;
			});

			if (!paymentHook) {
				throw new Error("More than one hook matched in the URL. Please disambiguate the URL");
			}

			matchingHooks = [paymentHook];
		}

		if (matchingHooks.length == 1) {
			hook = matchingHooks[0];

			return hook.executeWith(new URL(document.URL));
		}

		return '';
	}

	/**
	 * Updates the cart
	 * @param {String} storeVersion The store version
	 * @param {Number} totalAndQty The total quantity
	 * @returns {undefined}
	 */
	function updateCarts(storeVersion, totalAndQty) {
		var selectors = {
			v4: {
				qty: ".accesso.quantity",
				total: ".accesso.total"
			},
			v5: {
				qty: "[data-accesso-quantity]",
				total: "[data-accesso-total]"
			}
		};

		Array.prototype.slice.call(document.querySelectorAll(selectors[storeVersion].qty)).forEach(function (el) {
			var qty = totalAndQty.qty;
			el.innerHTML = qty;
			options.quantity = qty;
		});
		Array.prototype.slice.call(document.querySelectorAll(selectors[storeVersion].total)).forEach(function (el) {
			var total = totalAndQty.total;
			el.innerHTML = total;
			options.total = total;
		});
	}

	/**
	 * Restores a cart from local storage
	 * @returns {undefined}
	 */
	function restorePreviousCartFromLocalStorage() {
		try {
			if (options && options.url) {
				var container = document.createElement('div');
				container.setAttribute('id', 'accesso-shim');
				container.setAttribute('style', 'overflow:hidden;height:0px;');
				container.innerHTML = render(options.templates.shim);
				document.body.appendChild(container);
			}
		} catch (e) {
			// ignore
		}
	}

	/**
	 * V4 Page Interaction Hooks (Deprecated!)
	 * These are deprecated and due to be destroyed forever as soon as
	 * all clients are moved to the new V5 hooks
	 * They made me do it. Don't judge me!
	 * @deprecated
	 * @returns {undefined}
	 */
	function v4hooks() {
		// Merge script GET arguments into shared options object
		merge(qs.parse(scriptSrc(options.script_name)), options);

		populateDataLayerFromDataLayerAttribute();
		renderTo('.accesso.quickcart', options.templates.quickcart);
		renderTo('.accesso.promo', options.templates.promo);
		renderTo('.accesso.receiptform', options.templates.receiptform);

		if (v4HooksAdded) {
			return;
		}

		v4HooksAdded = true;

		// Listen to quantity/total messages from overlay and rs, and the data serving is done by a simple yet fast node api. ender to parent
		window.addEventListener("message", function (message) {
			// ignore this message if it's not an array of length 2

			try {
				var msg = JSON.parse(message.data);

				if (Object.prototype.toString.call(msg) !== "[object Array]" || msg.length !== 2) {
					return;
				}

				var qty = msg[0] || 0;
				var total = msg[1] || 0;

				updateCarts('v4', {
					qty: qty,
					total: total
				});
			} catch (e) {
				// ignore
			}
		});

		addEvent(".accesso.promo .promobtn", 'click', function (event) {
			var promocode = this.parentNode.querySelector(".accesso.promo .promotxt").value;
			overlay.open(event, 'packageList/promocode/' + promocode);
		});
		addEvent('.accesso.receiptform .receiptbtn', 'click', function (event) {
			var email = this.parentNode.parentNode.querySelector(".accesso.receiptform .receiptemailtxt").value;
			var phone = this.parentNode.parentNode.querySelector(".accesso.receiptform .receiptpntxt").value;
			overlay.open(event, 'orderLookup/' + email + '/' + phone);
		});
		addEvent('a', 'click', function (event) {
			if (!this.name && this.href.split('#').length > 1 && this.href.split('#')[1].length > 0) {
				// still look for other a tags that have a name attribute with this keyword value
				// accounting for a bug in the v4 accesso.js code
				var anchorNodeList = document.querySelectorAll('a');
				var anchors = [];

				Array.prototype.forEach.call(anchorNodeList, function (item) {
					if (typeof item.name !== 'undefined' && item.name != '') {
						anchors.push(item.name);
					}
				});

				if (anchors.length > 0 && anchors.indexOf(this.href.split('#')[1]) > -1) {
					return;
				}

				if (options.host.indexOf('sf-') === 0) {
					if (this.href.split('#')[1].indexOf('accesso=') > -1) {
						overlayLaunch(event, this.href.split('#')[1]);
					}
				} else {
					overlayLaunch(event, this.href.split('#')[1]);
				}
			}
		});
	}

	/**
	 * This is only used for scrolling Passport frame into view. I am refactoring so we don't have to always run logic if the store isn't even open. - MY
	 */
	if (!isBrandMode) {
		var rsTimeout;
		window.onresize = function () {
			// Will be null if Passport is not open
			var store_iframe = document.getElementById('override');

			if (store_iframe) {
				clearTimeout(rsTimeout);
				rsTimeout = setTimeout(function () {
					// Check both the height and width and then scroll into view once either is hit
					if (window.innerWidth <= 1024 || window.innerHeight <= 840) {
						store_iframe.scrollIntoView(false);
					}
				}, 200);
			}
		};
	}

	/**
	 * Initial Hooks on load of the script
	 * @param {String} message
	 * @returns {undefined}
	 */
	function initialListeners(message) {
		var msg;
		try {
			msg = JSON.parse(message.data);
			if (!msg || typeof msg.messageType !== 'string' || msg.messageType.indexOf('accesso') === -1) {
				return;
			}

			switch (msg.messageType) {
				case 'accesso:cartUpdate':
					if (msg.data.qty >= 0 && msg.data.total >= 0) {
						updateCarts('v5', {
							qty: msg.data.qty || 0,
							total: msg.data.total || 0
						});
					}
					break;
			}
		} catch (e) {
			// ignore
		}
	};

	/**
	 * Sets the iframe src for the store php
	 * @param {String} path The path
	 * @returns {undefined}
	 */
	function setIFrameSrcForStorePhp(path) {
		// If there is no merchant_id param and an m param exists,
		// rename the old m param to the new standard merchant_id param
		var queryParams = qs.parse(options.search);
		if (!queryParams.hasOwnProperty('merchant_id') && queryParams.hasOwnProperty('m')) {
			// rename the param
			queryParams['merchant_id'] = queryParams['m'];
			delete queryParams['m'];

			// Re-assemble the query string using the renamed param
			// the renamed param and its value will be located at the end of the query string
			options.search = '?' + qs.stringify(queryParams);
		}

		var ua = window.navigator ? window.navigator.userAgent : window.request ? window.request.headers['user-agent'] : 'No User Agent';
		var formFactor = userAgentMatch(ua);
		var launchMobileSite = !isBrandMode && (formFactor === DEVICE_TABLET || formFactor === DEVICE_MOBILE);
		var hostname = determineHostnameForLandingPage();

		if (hostname === 'localhost' && window.location.port) {
			hostname += ':' + window.location.port;
		}

		var launchUrl = "https://" + hostname + '/' + path + options.search;
		if (launchMobileSite && !isAAAPage()) {
			if (launchNewWindow(DEVICE_MOBILE)) {
				window.open(launchUrl, '_blank');
			} else {
				window.location.assign(launchUrl);
			}
		} else {
			try {
				var iFrame = document.getElementById("override");
				iFrame.src = launchUrl;
				iFrame.allow = 'geolocation; microphone; camera; payment';

				window.addEventListener('message', function applePayEventListener(message) {
					var msg,
						postBackMsg,
						queryParamParts,
						trimmedQueryParams = [];
					try {
						msg = JSON.parse(message.data);
						if (!msg || typeof msg.messageType !== 'string' || msg.messageType.indexOf('accesso') === -1) {
							return;
						}

						switch (msg.messageType) {
							case 'accesso:checkForApplePay':
								postBackMsg = JSON.stringify({
									isAvailable: typeof window.ApplePaySession === 'function' && ApplePaySession.supportsVersion(msg.version) && ApplePaySession.canMakePayments(),
									messageType: 'accesso:applePayAvailability'
								});
								iFrame.contentWindow.postMessage(postBackMsg, applePayChildUrl);
								break;
							case 'accesso:applePayStartSession':
								startApplePaySession(iFrame, msg, message);
								break;
							case 'accesso:applePayCancel':
								if (applePaySession) {
									applePaySession.oncancel();
								}
								applePaySession = null;
								break;
							case 'accesso:applePayValidateResponse':
								if (applePaySession) {
									applePaySession.completeMerchantValidation(msg.data);
								}
								break;
							case 'accesso:applePayPaymentResponse':
								if (applePaySession) {
									applePaySession.completePayment({ status: msg.status });
								}
								break;
							case 'accesso:applePayShippingMethodUpdated':
								if (applePaySession) {
									applePaySession.completeShippingMethodSelection(msg.data);
								}
								break;
							case 'accesso:didRoute':
								if (isBrandMode) {
									window.scrollTo({
										behavior: 'smooth',
										top: 0
									})
								}
								break;
							case 'accesso:cartUpdate':
								if (msg.data.qty >= 0 && msg.data.total >= 0) {
									updateCarts('v5', {
										qty: msg.data.qty || 0,
										total: msg.data.total || 0
									});
								}
								break;
							case 'accesso:applePayShippingContactUpdated':
								if (applePaySession) {
									applePaySession.completeShippingContactSelection(msg.data);
									break;
								}
							case 'accesso:getCurrentUrl':
								postBackMsg = JSON.stringify({
									url: root.location.href,
									messageType: 'accesso:setCurrentUrl'
								});
								iFrame.contentWindow.postMessage(postBackMsg, '*');
								break;
							case 'accesso:accessoPayRedirect':
								if (msg.method === 'POST') {
									var redirectFormId = 'acsp_rdfrm_main';
									var redirectForm = document.getElementById(redirectFormId);
									var form,
										formField,
										key;
									if (!redirectForm) {
										form = document.createElement('form');
										document.body.appendChild(form);
									}
									form.id = redirectFormId;
									form.name = redirectFormId;
									form.action = msg.url ? msg.url.replace(/(<([^>]+)>)|^javascript/ig, '') : '';
									form.enctype = 'application/x-www-form-urlencoded';
									form.innerHTML = '';
									form.style.display = 'none';
									form.method = msg.method;
									for (key in msg.redirectParams) {
										formField = document.createElement('input');
										formField.type = 'hidden';
										formField.name = key;
										formField.value = msg.redirectParams[key];

										form.appendChild(formField);
									}
									form.submit();
								} else {
									root.location.href = msg.url ? msg.url.replace(/(<([^>]+)>)|^javascript/ig, '') : '';
									return;
								}
								break;
							case 'accesso:accessoPayClearQueryParams':
								if (Array.isArray(msg.queryParams)) {
									try {
										queryParamParts = new URL(root.location.href).parts();
									} catch (err) {
										queryParamParts = [];
									}
									queryParamParts.forEach(function trimQueryParams(qp) {
										if (msg.queryParams.indexOf(qp.k) === -1) {
											trimmedQueryParams.push(qp.k + '=' + qp.v);
										}
									});
									root.history.replaceState({}, document.title, root.location.pathname + (trimmedQueryParams.length ? '?' + trimmedQueryParams.join('&') : ''));
								}
								break;
							default:
							// ignore
						}
				 } catch (e) {
					// ignore
				 }
				});
			} catch (e) {
				warn('IFrame with id: override, may be missing from parent page.  Please check page source. ');
			}
		}
	}

	/**
	 * Determins a landing page host
	 * @returns {String} The hostname
	 */
	function determineHostnameForLandingPage() {
		var hostname = document.location.hostname;
		if (window.location.pathname.indexOf('aaa.jsp') > -1) {
			// rework the hostname for the AAA-SF integration page
			hostname = 'sf-aaa.secure.ceiris.com';

			if (document.location.hostname.match(/shop/gi)) {
				hostname = "sf-aaa.secure.accesso.com";
			}

			if (document.location.hostname.match(/stg-shop/gi)) {
				hostname = "sf-aaa.stg-store.accesso.com";
			}

			if (document.location.hostname.match(/dev-shop/gi)) {
				hostname = "sf-aaa.dev-secure.accesso.com";
			}

			if (document.location.hostname.match(/test-shop/gi)) {
				hostname = "sf-aaa.test-secure.accesso.com";
			}
		}

		return hostname;
	}

	/**
	 * V5 Page Interaction Hooks (Migrate everyone to these!)
	 * @return {undefined}
	 */
	function v5hooks() {
		// Merge data-accesso options into shared options object
		var scriptOpts = {};
		var optsAttr = document.querySelector('[data-accesso]');
		if (optsAttr) {
			optsAttr
				.getAttribute('data-accesso')
				.split(',')
				.forEach(function (item) {
					scriptOpts[item.split('=')[0]] = item.split('=')[1];
				});
			merge
				(scriptOpts
					, options
				);
		}

		populateDataLayerFromDataLayerAttribute();

		// Render quickcart,promo,receiptform elements where appropriate
		renderTo("[data-accesso-quickcart]", options.templates.quickcart);
		renderTo("[data-accesso-promoform]", options.templates.promo);
		renderTo("[data-accesso-receiptform]", options.templates.receiptform);

		// Don't re-add events from hereon
		if (v5HooksAdded) {
			if (isBrandMode) {
				// if this is brandmode, we need to open the iframe
				overlay.open(null);
			}
			return;
		}

		v5HooksAdded = true;

		addEvent('[data-accesso-promoform] .promobtn', 'click', function (event) {
			var promocode = this.parentNode.querySelector("[data-accesso-promoform] .promotxt").value;

			if (promocode) {
				// Encoding the promocode helps resolve most special character issues, except when / is used, so we must strip it out.
				promocode = promocode.replace(/\//g, '');

				// encode the promocode, as our code expects to decode it.
				overlay.open(event, 'packageList/promocode/' + encodeURIComponent(promocode));
			}
		});

		addEvent('[data-accesso-orderlookup]', 'click', function (event) {
			overlay.open(event, 'orderLookup');
		});

		addEvent('[data-accesso-all]', 'click', function (event) {
			var reference = this.getAttribute('data-accesso-all');
			if (validateReference(reference)) {
				checkLaunchMethod(event, reference);
			} else {
				throw new Error('Unable to launch (data-accesso-all) due to invalid target.  Please ensure that the tag is formatted properly.')
			}
		});

		addEvent('[data-accesso-launch]', 'click', function (event) {
			var reference = this.getAttribute('data-accesso-launch');
			if (reference === null) {
				throw new Error('Unable to launch (data-accesso-launch) due to invalid target.  Please ensure that the tag is formatted properly.')
			} else {
				overlayLaunch(event, reference);
			}
		});

		addEvent('[data-accesso-keyword]', 'click', function (event) {
			var reference = this.getAttribute('data-accesso-keyword');
			if (!reference) {
				reference = this.getAttribute('data-accesso-keyword');
			}
			overlay.open(event, 'packageList/keyword/' + reference);
		});

		addEvent('[data-accesso-promo]', 'click', function (event) {
			var reference = this.getAttribute('data-accesso-promo');
			overlay.open(event, 'packageList/promocode/' + reference);
		});

		addEvent('[data-accesso-package]', 'click', function (event) {
			var reference = this.getAttribute('data-accesso-package');
			if (!reference) {
				reference = this.getAttribute('data-accesso-package');
			}
			overlay.open(event, 'packageDetails/' + reference);
		});

		addEvent('[data-accesso-gift-membership]', 'click', function (event) {
			if (validateReference(this.getAttribute('data-accesso-gift-membership'))) {
				overlay.open(event, 'gift-membership');
			} else {
				throw new Error('Unable to launch (data-accesso-gift-membership) due to invalid target.  Please ensure that the tag is formatted properly.')
			}
		});

		addEvent('[data-accesso-module]', 'click', function (event) {
			var moduleName = this.getAttribute('data-accesso-module');
			if (validateReference(moduleName)) {
				var pathToOpen = moduleName;
				var params = this.getAttribute('data-accesso-params');
				if (validateReference(params)) {
					params.split(',').forEach(function (param) {
						pathToOpen += '/' + param;
					});
				}
				overlay.open(event, pathToOpen);
			} else {
				throw new Error('Unable to launch (data-accesso-module) due to invalid target.  Please ensure that the tag is formatted properly.')
			}
		});
	}

	/**
	 * Check the type of launch method and launch accordingly
	 * @protected
	 * @param {Object} event event object
	 * @param {String} reference reference
	 * @return {undefined}
	 */
	function checkLaunchMethod(event, reference) {
		var type = [],
			genericLaunch = false,
			launchMethod,
			launchValue,
			path;
		if (reference.indexOf(':') === -1) {
			throw new Error('Unable to launch data-accesso-all. Please ensure that it has a key value pair');
		}
		type = reference.split(':');
		launchMethod = type[0];
		launchValue = type[1];
		switch (launchMethod) {
			case 'keyword':
				path = 'packageList/keyword/' + launchValue;
				break;
			case 'package':
				path = 'packageDetails/' + launchValue;
				break;
			case 'gift-membership':
				path = 'gift-membership';
				break;
			case 'promo':
				path = 'packageList/promocode/' + launchValue;
				break;
			case 'module':
				path = getPathForModule(launchValue, event);
				break;
			case 'faq':
				path = 'open-knowledge-base';
				break;
			case 'launch':
				genericLaunch = true;
				path = launchValue;
				break;
			default:
				genericLaunch = true;
				path = '';
				break;
		}
		if (genericLaunch) {
			overlayLaunch(event, path);
		} else {
			overlay.open(event, path);
		}
	}

	/**
	 * Get path for module
	 * @protected
	 * @param {String} moduleName module name
	 * @param {Object} event event
	 * @returns {String} path
	 */
	function getPathForModule(moduleName, event) {
		var params = event.target.getAttribute('data-accesso-params');
		if (validateReference(params)) {
			return params.split(',').reduce(function (path, param) {
				return path + '/' + param;
			}, moduleName);
		}
		return moduleName;
	}

	/**
	 * Updates the accesso data layer
	 * @param {String} key the key
	 * @param {*} value Any value
	 * @returns {undefined}
	 */
	function updateAccessoDataLayer(key, value) {
		if (key !== undefined && value !== undefined) {
			accessoDataLayer[key] = value;
		}
	}

	var allowedAccessoEvents = ['purchase'];
	var accessoEventMap = {}

	/**
	 * Fires the callbacks for any registered event
	 * @param {string} name The name of the event to fire
	 * @param {*} data The data to pass to any of the listeners
	 */
	function fireAccessoEvent(name, data) {
		if (Array.isArray(accessoEventMap[name])) {
			accessoEventMap[name].forEach(function(callback) {
				callback(data);
			});
		}
	}

	/**
	 * Registers an event listener to an accesso event
	 * @param {string} name The name of the event
	 * @param {Function} callback The callback function
	 */
	function onAccessoEvent(name, callback) {
		if (allowedAccessoEvents.indexOf(name) === -1) {
			throw new Error('Event "' + name +'" is not supported by accesso.on()');
		}

		if (!Array.isArray(accessoEventMap[name])) {
			accessoEventMap[name] = [];
		}
		accessoEventMap[name].push(callback);
	}

	/**
	 * Deregisters an event listener
	 * @param {string} name The name of the event
	 * @param {Function} callback The callback function
	 */
	function removeAccessoEvent(name, callback) {
		if (allowedAccessoEvents.indexOf(name) === -1) {
			throw new Error('Event "' + name +'" is not supported by accesso.off()');
		}

		if (!Array.isArray(accessoEventMap[name])) return;

		var foundIndex = accessoEventMap[name].indexOf(callback);
		if (foundIndex < 0) {
			return console.warn('accesso.off() could not locate the event for "' + name + '"', callback);
		}
		accessoEventMap[name].splice(foundIndex, 1);
	}

	// Export variables to the outside world
	accesso.options = options;
	accesso.overlay = overlay;
	accesso.URL = URL;
	accesso.URLHook = URLHook;
	accesso.URLCallbackHook = URLCallbackHook;
	accesso.attachListener = attachListener;
	accesso.bootstrapCompleteHandler = undefined;
	accesso.validateReference = validateReference;
	accesso.reinitializeHooks = v5hooks;
	accesso.updateAccessoDataLayer = updateAccessoDataLayer;
	accesso.on = onAccessoEvent;
	accesso.off = removeAccessoEvent;

	root.accesso = accesso;

	// Run V5 hooks is page contains them, else default to v4 hooks. Unless the version has been specifically set via data-accesso-hooks-version
	onDomReady(function () {
		// Set options.url based on url of the script
		var p = parseURL(scriptSrc(options.script_name));

		// this prepends data-accesso-launch urls with "embed/" and breaks keyword launching POST hostname change on localhost testing embed
		// TODO investigate solution for this for future
		if (p.host && Array.isArray(p.path) && p.path.length > 1) {
			var merlinPathMerchant = p.path.filter(function (item) {
				return (item.toLowerCase() == 'me-slb' || item.toLowerCase() == 'me-tp')
			});
			if (merlinPathMerchant.length > 0) {
				options.url = '//' + p.host + '/' + p.path[0];
			} else {
				options.url = '//' + p.host;
			}
		}

		if (p.host && p.host.indexOf('localhost') != -1) {
			options.url = '//' + p.host;
		}

		options.host = p.host;

		applePayChildUrl = p.protocol + '//' + p.host;

		if (window.location.search !== undefined) {
			options.search = window.location.search;
		}

		var version = '';
		var hookVersionAttribute = document.querySelector('[data-accesso-integration-version]');

		if (validateReference(hookVersionAttribute)) {
			version = hookVersionAttribute.getAttribute('data-accesso-integration-version');
		}

		// Add listener for initial hooks
		window.addEventListener('message', initialListeners);

		if (document.querySelectorAll([
			'[data-accesso]',
			'[data-accesso-all]',
			'[data-accesso-brand-mode]',
			'[data-accesso-launch]',
			'[data-accesso-quantity]',
			'[data-accesso-total]',
			'[data-accesso-package]',
			'[data-accesso-promo]',
			'[data-accesso-keyword]',
			'[data-accesso-module]'
		].join(',')).length >= 1 || version == '5') {
			v5hooks();
		} else {
			v4hooks();
		}

		restorePreviousCartFromLocalStorage();

		// set styles
		if (!isBrandMode) {
			overlay.setupStyles();
		}

		// handle any GET hooks
		processURLHooks();

		// bootstrap complete, call handler if present
		if (accesso && typeof accesso.bootstrapCompleteHandler === 'function') {
			try {
				accesso.bootstrapCompleteHandler();
			} catch (e) {
				// unable to execute handler hook
			}
		}
	});

	/**
	 * Starts an apple pay session
	 * @protected
	 * @param {HTMLElement} iframeContainer The iframe container element
	 * @param {Object} data The data passed
	 * @param {Event} event The event object if needed
	 * @returns {undefined}
	 */
	function startApplePaySession(iframeContainer, data, event) {
		var version = data.version,
			paymentRequest = data.paymentRequest;

		try {
			applePaySession = new ApplePaySession(version, paymentRequest);
			addApplePayEvents(iframeContainer, applePaySession, paymentRequest);
			applePaySession.begin();
		} catch (er) {
			warn('[ApplePay Error]: ' + er);
		}
	}

	/**
	 * Assigns the events to the apple pay session
	 * @protected
	 * @param {HTMLElement} iframeContainer The iframe container
	 * @param {ApplePaySession} session The apple pay session instance
	 * @param {Object} paymentRequest Our payment request from V5
	 * @returns {undefined}
	 */
	function addApplePayEvents(iframeContainer, session, paymentRequest) {
		var updatedTotal = { newTotal: paymentRequest.total };

		// ADD SESSION EVENTS
		session.oncancel = function () {
			iframeContainer.contentWindow.postMessage(JSON.stringify({ messageType: 'accesso:applePayCancel' }), applePayChildUrl);
		};
		session.onvalidatemerchant = onValidateMerchant;
		session.onshippingmethodselected = onShippingMethodSelected;
		session.onshippingcontactselected = onShippingContactSelected;
		session.onpaymentmethodselected = onPaymentMethodSelected;
		session.onpaymentauthorized = onPaymentAuthorized;

		/**
		 * Called on validate
		 * @param {Event} evt The event
		 * @returns {undefined}
		 */
		function onValidateMerchant(evt) {
			iframeContainer.contentWindow.postMessage(JSON.stringify({
				host: window.location.host,
				messageType: 'accesso:applePayValidateMerchant',
				validationURL: evt.validationURL
			}), applePayChildUrl);
		}

		/**
		 * When the payment method changes.
		 * @public
		 * @callback
		 * @function
		 * @read https://developer.apple.com/documentation/applepayjs/applepaysession/1778013-onpaymentmethodselected
		 * @param {ApplePayPaymentMethodSelectedEvent} evt The ApplePayPaymentMethodSelectedEvent event object.
		 * @returns {undefined}
		 */
		function onPaymentMethodSelected() {
			session.completePaymentMethodSelection(updatedTotal);
		}

		/**
		 * When the shipping method changes. This should never be called since we dont allow them to change shipping methods here.
		 * @public
		 * @callback
		 * @function
		 * @read https://developer.apple.com/documentation/applepayjs/applepaysession/1778028-onshippingmethodselected
		 * @param {ApplePayShippingMethodSelectedEvent} evt The ApplePayShippingMethodSelectedEvent event object.
		 * @returns {undefined}
		 */
		function onShippingMethodSelected(evt) {
			iframeContainer.contentWindow.postMessage(JSON.stringify({
				host: window.location.host,
				messageType: 'accesso:applePayShippingMethodSelected',
				shippingMethod: evt.shippingMethod,
				updatedTotal: updatedTotal,
				paymentRequest: paymentRequest
			}), applePayChildUrl);
		}

		/**
		 * When the shipping contact changes.
		 * @public
		 * @callback
		 * @function
		 * @read https://developer.apple.com/documentation/applepayjs/applepaysession/1778009-onshippingcontactselected
		 * @param {ApplePayShippingContactSelection} evt The ApplePayShippingContactSelection event object.
		 * @returns {undefined}
		 */
		function onShippingContactSelected(evt) {
			iframeContainer.contentWindow.postMessage(JSON.stringify({
				host: window.location.host,
				messageType: 'accesso:applePayShippingContactSelected',
				shippingContact: evt.shippingContact,
				updatedTotal: updatedTotal,
				paymentRequest: paymentRequest
			}), applePayChildUrl);
		}

		/**
		 * When the user clicks pay.
		 * @public
		 * @callback
		 * @function
		 * @read https://developer.apple.com/documentation/applepayjs/applepaysession/1778020-onpaymentauthorized
		 * @param {ApplePayPaymentAuthorizedEvent} evt The ApplePayPaymentAuthorizedEvent event object.
		 * @returns {undefined}
		 */
		function onPaymentAuthorized(evt) {
			iframeContainer.contentWindow.postMessage(JSON.stringify({
				host: window.location.host,
				messageType: 'accesso:applePayAuthorized',
				payment: evt.payment,
				paymentRequest: paymentRequest
			}), applePayChildUrl);
		}
	}
})(this);
